import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("img", {
        class: "information_image_size",
        alt: "Imagen",
        src: $options.imgUrl($setup.urlImg)
      }, null, 8, _hoisted_1)
    ]),
    ( $options.imgUrl($setup.urlImg) == '' )
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          type: "modern"
        }))
      : _createCommentVNode("", true)
  ], 64))
}