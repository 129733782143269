import { reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class InfoBeneficiarioAccionista_JuridicoValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoBeneficiarioAccionista_Juridico = reactive({
             Beneficiario:[{
                NombreAJ_Beneficiario: '',
                NombreBeneficiario: '',
                TipoIdBeneficiario: '',
                NumeroIdentificacionBeneficiario: '',
                EsPEPBeneficiario: '',
                VinculosFamPEP_Beneficiario: '',
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const reglasValidacion = {
            Beneficiario: {
                 $each: helpers.forEach({
                    NombreAJ_Beneficiario: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     NombreBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoIdBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     EsPEPBeneficiario: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     VinculosFamPEP_Beneficiario: {
                        required: helpers.withMessage(requiredMessage, required),
                    }
                 })
             }       
         };

        return [objetoInfoBeneficiarioAccionista_Juridico, reglasValidacion] as const;
    }
}

export default InfoBeneficiarioAccionista_JuridicoValidacion;