import { reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class PepValidacion{

     public ObtenerReglasValidacion() {

         const objetoPep = reactive({
             campos: [{
                 Nombre: '',
                 TipoId: '',
                 NumeroIdentificacion: '',
                 TipoVinculo: '',
                 TipoPep: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const reglasValidacion = {
             campos: {
                 $each: helpers.forEach({
                     Nombre: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     TipoId: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacion: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoVinculo: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoPep: {
                         required: helpers.withMessage(requiredMessage, required),
                     }
                 })
             }
         };

        return [objetoPep, reglasValidacion] as const;
    }
}

export default PepValidacion;
