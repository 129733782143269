import { reactive } from 'vue'
import { required, helpers, email } from '@vuelidate/validators'

class InfoRepresentanteTitularValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoRepresentanteTitular = reactive({
             RLTitular: [{
                NombreRLTitular: '',
                TipoIdRLTitular: '',
                NumeroIdentificacionRLTitular: '',
                CargoEmpresaRLTitular: '',
                CorreoRLTitular: '',
                EsPEPRLTitular: '',
                VinculosFamPEP_RLTitular: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         const emailMessage = 'valide formato e-mail';
         
         const reglasValidacion = {
            RLTitular: {
                 $each: helpers.forEach({
                    NombreRLTitular: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     TipoIdRLTitular: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionRLTitular: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     CargoEmpresaRLTitular: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     CorreoRLTitular:{
                        email: helpers.withMessage(emailMessage, email)
                     },
                     EsPEPRLTitular: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     VinculosFamPEP_RLTitular: {
                        required: helpers.withMessage(requiredMessage, required),
                    }
                 })
             }     
         };

        return [objetoInfoRepresentanteTitular, reglasValidacion] as const;
    }
}

export default InfoRepresentanteTitularValidacion;