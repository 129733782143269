import { reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class InfoAccionista_NaturalValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoAccionista_Natural = reactive({
            An: [{
                NombreAN: '',
                TipoIdAN: '',
                NumeroIdentificacionAN: '',
                ParticipacionCapitalAN: '',
                EsPEPAN: '',
                VinculosFamPEP_AN: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const reglasValidacion = {
            An: {
                 $each: helpers.forEach({
                    NombreAN: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     TipoIdAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     ParticipacionCapitalAN: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                     EsPEPAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     VinculosFamPEP_AN: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                 })
             }
         };

        return [objetoInfoAccionista_Natural, reglasValidacion] as const;
    }
}

export default InfoAccionista_NaturalValidacion;