enum Seccion{
    
    CONTACTO = 1,
    PEP = 2,
    CLIENTE = 3,
    FINANCIERA = 4,
    ADICIONAL = 5,
    LEGAL,
    ACCIONISTA
}

export default Seccion