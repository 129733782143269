import { reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class InfoAccionista_JuridicoValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoAccionista_Juridico = reactive({
             Aj: [{
                NombreAJ: '',
                TipoIdAJ: '',
                NumeroIdentificacionAJ: '',
                ParticipacionCapitalAJ: '',
                PaisAJ: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const reglasValidacion = {
            Aj: {
                 $each: helpers.forEach({
                    NombreAJ: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     TipoIdAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     ParticipacionCapitalAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     PaisAJ: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                 })
             }       
         };

        return [objetoInfoAccionista_Juridico, reglasValidacion] as const;
    }
}

export default InfoAccionista_JuridicoValidacion;