import { computed, reactive } from 'vue'
import { helpers, requiredIf } from '@vuelidate/validators'

  class InfoAdicionalValidacion {
  
    public ObtenerReglasValidacion() {

      const applicationDefault = 'application/default';

      const documentosIdentidad: Archivo = {
        ExtensionesArchivos: [{
          Id: 0,
          Extension: applicationDefault
        }],
      }

      const certificados: Archivo = {
        ExtensionesArchivos: [{
          Id: 0,
          Extension: applicationDefault
        }],
      }   

      const informacionFinanciera: undefined | {} = undefined;

      const estadosFinancieros: any = {
        InformacionFinanciera: informacionFinanciera,
        ExtensionesArchivos: [{
          Id: 0,
          Extension: applicationDefault
        }],
      }

      const firma = {
        Nombre: '',
        ExtensionesArchivos: [{
          Id: 0,
          Extension: applicationDefault
        }],
        
      }

      const formData = new FormData();
  
      const objetoArchivos = reactive({
        DocumentosIdentidad: documentosIdentidad,
        Certificados: certificados,
        EstadosFinancieros: estadosFinancieros,
        Firma: firma,
        Archivos: formData,
        TamañoArchivo: 0,
        NombresArchivos: [{
          Id: 0,
          Nombre: ''
        }],
        BuenasPracticas: '',
      });
  
      const maximoTamañoArchivo: number = 20971520;
  
      const ValidarLongitud = (TamañoArchivo: number) =>
        helpers.withParams({
            type: 'TamañoArchivo',
            value: TamañoArchivo
          },
          (TamañoArchivo: number) => !(TamañoArchivo > maximoTamañoArchivo)
        )
      
        const ValidarObligatorio = (objeto: Extension[]) =>
        helpers.withParams({
            type: 'objeto',
            value: objeto
          },
          (objeto: Extension[]) => (objeto.length > 1)
        )
      
        const ValidarObligatorioFinanciero = (objetoInfoFinanciera: any, objeto: Extension[]) =>
        helpers.withParams({
            type: 'objetoInfoFinanciera',
            value: objetoInfoFinanciera,
          },
          () => (objeto.length > 1) ||
                (objetoInfoFinanciera == undefined && objeto.length == 1) ||
                (objetoInfoFinanciera != undefined && objeto.length > 1)
        )
  
      const ValidarExtension = (extensionesArchivo: Extension[]) =>
        helpers.withParams({
            type: 'extensionesArchivo',
            value: extensionesArchivo
          },
  
          (archivos: Extension[]) =>
  
          !(archivos.filter(archivo =>
            archivo.Extension != 'image/jpeg' &&
            archivo.Extension != 'image/png' &&
            archivo.Extension != 'application/pdf' &&
            archivo.Extension != 'application/default').length > 0)
        );
      
        const ValidarExtensionFirma = (extensionFirma: Extension[]) =>
        helpers.withParams({
            type: 'extensionFirma',
            value: extensionFirma
          },
  
          (firma: Extension[]) =>
  
          !(firma.filter(firma =>
            firma.Extension != 'application/pdf' &&
            firma.Extension != 'application/default').length > 0)
        );
  
      const ValidarNombre = (NombresArchivos: Nombre[]) =>
        helpers.withParams({
            type: 'NombresArchivos',
            value: NombresArchivos
          },
  
          (archivos: Nombre[]) =>
  
          (
            (archivos.find((objetoNombreArchivo: Nombre, indice: number) => {
            return archivos.find((file: Nombre, index: number) =>
              objetoNombreArchivo.Nombre === file.Nombre && indice !== index)
          })) === undefined)
        );
      
      const ValidarNombreFirma = (nombreFirma: string) =>
        
        helpers.withParams({
          type: 'nombreFirma',
          value: nombreFirma
        },
          (nombreFirma: string) => !(nombreFirma !== 'CartaFirmaRepresentante.pdf') || (nombreFirma == '')
        )
  
      const errorTamaño = 'El tamaño no puede superar 20MB';
      const errorExtension = 'La extensión válida de tus archivos es ( PNG,JPEG,PDF )';
      const errorObligatorio = 'Debes adjuntar al menos un archivo'
      const errorNombreDuplicado = 'Asegúrate de que tus archivos no contengan el mismo nombre';
      const errorCheck = 'Selecciona';
          
      const reglasValidacion = computed(() => {
        return {
          TamañoArchivo: {
            tamaño: helpers.withMessage(errorTamaño, ValidarLongitud(objetoArchivos.TamañoArchivo))
          },
          NombresArchivos: {
            nombre: helpers.withMessage(errorNombreDuplicado, ValidarNombre(objetoArchivos.NombresArchivos))
          },
          DocumentosIdentidad: {
            ExtensionesArchivos: {
              extension: helpers.withMessage(errorExtension, ValidarExtension(objetoArchivos.DocumentosIdentidad.ExtensionesArchivos)),
              obligatorio: helpers.withMessage(errorObligatorio, ValidarObligatorio(objetoArchivos.DocumentosIdentidad.ExtensionesArchivos))
            },
          },
          Certificados: {
            ExtensionesArchivos: {
              extension: helpers.withMessage(errorExtension, ValidarExtension(objetoArchivos.Certificados.ExtensionesArchivos)),
              obligatorio: helpers.withMessage(errorObligatorio, ValidarObligatorio(objetoArchivos.Certificados.ExtensionesArchivos))
            }
          },
          EstadosFinancieros: {
            ExtensionesArchivos: {
              extension: helpers.withMessage(errorExtension, ValidarExtension(objetoArchivos.EstadosFinancieros.ExtensionesArchivos))
            },
            InformacionFinanciera: { 
              obligatorio2: helpers.withMessage(errorObligatorio, ValidarObligatorioFinanciero(
                objetoArchivos.EstadosFinancieros.InformacionFinanciera,
                objetoArchivos.EstadosFinancieros.ExtensionesArchivos))
            }
          },
          BuenasPracticas: {
            required: helpers.withMessage(errorCheck, requiredIf(objetoArchivos.BuenasPracticas !== 'Si'))
            
          },  
          Firma: {
            Nombre: {
              NombreFirma: helpers.withMessage("El nombre del archivo debe ser CartaFirmaRepresentante", ValidarNombreFirma(objetoArchivos.Firma.Nombre)),
            },
            ExtensionesArchivos: {
              extension: helpers.withMessage("La extensión válida de tu archivo es PDF", ValidarExtensionFirma(objetoArchivos.Firma.ExtensionesArchivos)),
              obligatorio: helpers.withMessage(errorObligatorio, ValidarObligatorio(objetoArchivos.Firma.ExtensionesArchivos)), 
            }
          },
        }
      });
  
        return [objetoArchivos, reglasValidacion] as const;
    }
  }
  
  export default InfoAdicionalValidacion;