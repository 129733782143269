import { reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class InfoPepAccionista_JuridicoValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoPepAccionista_Juridico = reactive({
             PepAJ:[{
                NombreAJ_PEP: '',
                NombrePEP: '',
                TipoIdPEPAJ: '',
                NumeroIdentificacionPEPAJ: '',
                TipoVinculoPEPAJ: '',
                TipoPEPAJ: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const reglasValidacion = {
            PepAJ: {
                 $each: helpers.forEach({
                    NombreAJ_PEP: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     NombrePEP: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoIdPEPAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionPEPAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoVinculoPEPAJ: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoPEPAJ: {
                        required: helpers.withMessage(requiredMessage, required),
                    }
                 })
             }       
         };

        return [objetoInfoPepAccionista_Juridico, reglasValidacion] as const;
    }
}

export default InfoPepAccionista_JuridicoValidacion;