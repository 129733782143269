import { computed, reactive } from 'vue'
import { required, helpers, requiredIf } from '@vuelidate/validators'

class clienteValidacion{
    public ObtenerReglasValidacion() {
        const objetoCliente =reactive({
            Nacionalidad:'',
            Ocupacion:'',
            DocumentoConstitucion:'',
            Numero:'',
            PaisConstitucion:'',
            FechaConstitucion:'',
            RegistroPublico:'',
            CodigoCIIU1:'',
            CodigoCIIU2:'',
            EntidadVigilada:'',
            EntidadControl:'',
            SistemaLAFT:'',
            FechaImplementacion:'',
            ActivosVirtuales:''
        })

        const reglasValidacion = computed(()=>{
            return{
                PaisConstitucion: { 
                    required: helpers.withMessage('Ingrese país de constitución', required) 
                },
                FechaConstitucion: { 
                    required: helpers.withMessage('Ingrese fecha de constitución', required) 
                },
                CodigoCIIU1: { 
                    required: helpers.withMessage('Ingrese actividad económica principal', required) 
                },
                EntidadVigilada: { 
                    required: helpers.withMessage('Seleccione', required) 
                },
                EntidadControl: { 
                    required: helpers.withMessage('Ingrese normas', requiredIf(objetoCliente.EntidadVigilada === "Si")) 
                },
                SistemaLAFT: { 
                    required: helpers.withMessage('Seleccione', required) 
                },
                FechaImplementacion: { 
                    required: helpers.withMessage('Ingrese fecha', requiredIf(objetoCliente.SistemaLAFT === "Si")) 
                },
                ActivosVirtuales: { 
                    required: helpers.withMessage('Seleccione', required) 
                }, 
            }
        })

        return [objetoCliente, reglasValidacion] as const;
    }
}
export default clienteValidacion;