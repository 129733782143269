<template>
<el-form>
    <el-row :gutter="24">
      <el-col :span="24">
        <h1 class="section-text">Representantes legales suplentes</h1>
      </el-col>
    </el-row>

    <div class="repSuplente">
    <el-row :gutter="24">
      <el-col :span="22">
        <el-table height="200" :data="objetoInfoRepresentanteSuplente.RLSuplente" :span="24">

          <el-table-column width="20%" >
              <template v-slot="scope">
                 <span><strong>{{scope.$index + 1}}</strong></span>
              </template>
          </el-table-column>

           <el-table-column class="section-text-color" label="Nombre de representantes legales suplentes" width="228px;" >
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NombreRLSuplente" @change="ValidarCamposFila(scope.$index)"/>
            </template>
          </el-table-column>

         <el-table-column label="Tipo ID" width="140px;">
            <template v-slot="scope">
              <el-select v-model="scope.row.TipoIdRLSuplente" placeholder="Seleccionar" @change="ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.tipoDeIdentificacion" :key="item.codigo" :label="item.valor" :value="item.valor" />
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="N° ID" width="150px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.NumeroIdentificacionRLSuplente" @change="ValidarCamposFila(scope.$index)"/>
            </template>
          </el-table-column>

          <el-table-column label="Cargo en la empresa" width="200px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.CargoEmpresaRLSuplente" @change="ValidarCamposFila(scope.$index)"/>
            </template>
          </el-table-column>

          <el-table-column label="Correo electrónico (e-mail)" width="250px;">
            <template v-slot="scope">
              <el-input type="text" v-model="scope.row.CorreoRLSuplente" @change="ValidarCamposFila(scope.$index)"/>
              <el-form-item v-for="error in validacionInfoRepresentanteSuplente.RLSuplente.$each.$response.$errors[scope.$index].CorreoRLSuplente" :key="error" class="section-error-message">
                {{ error.$message }}
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column label="¿Es PEP?" class-name="header_color" width="130px;">
            <template #header>
            <span>¿Es PEP?* </span>
            <el-tooltip
              content="PEP es la Persona Expuesta Políticamente."
              placement="top"
              open-delay="0"
              effect="customized"
            >
              <font-awesome-icon :icon="['fas', 'info-circle']" color="#00805d" style="font-size: 13px;" />
            </el-tooltip>
            </template>
            <template v-slot="scope">
              <el-select v-model="scope.row.EsPEPRLSuplente" placeholder="Seleccionar" @change="ValidaSiPEPRS();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor"/>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column label="¿Tiene vínculos familiares con PEP?" width="180px;" class-name="header_color">
            <template #header>
            <span>¿Tiene vínculos familiares con PEP?* </span>
            <el-tooltip
              placement="top"
              open-delay="0"  
              content="Las personas que tengan una sociedad conyugal, de hecho, o derecho con las personas expuestas políticamente nacionales o extranjeras, así como a sus 
              familiares hasta el segundo grado de consanguinidad, segundo de afinidad y primero civil."    
              effect="customized"        
            >        
              <font-awesome-icon :icon="['fas', 'info-circle']" color="#00805d" style="font-size: 13px;" />
            </el-tooltip>
            </template>
            <template v-slot="scope">
              <el-select v-model="scope.row.VinculosFamPEP_RLSuplente" placeholder="Seleccionar" @change="ValidaSiPEPRS();ValidarCamposFila(scope.$index)">
                <el-option v-for="item in parametricas.generico" :key="item.codigo" :label="item.valor" :value="item.valor"/>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column width="50">
            <template v-slot="scope">
              <el-button v-if="scope.$index > 0"  circle class="dinamic-form-button" size="small" type="success" @click="EliminarFilaRLSuplente(scope.$index);ValidaSiPEPRS();">
                <font-awesome-icon
                :icon="['fas', 'minus']"
                />
              </el-button>
            </template>
          </el-table-column>

        </el-table>
      </el-col>

      <el-col :span="2">
        <el-button circle class="dinamic-form-button" size="small" type="success" @click="AgregarFilaRLSuplente">
          <font-awesome-icon
                :icon="['fas', 'plus']"
                />
        </el-button>
      </el-col>
    </el-row>
    </div>
</el-form>
</template>

<script>

import useVuelidate from '@vuelidate/core';
import { defineComponent, onMounted } from 'vue';
import InfoRepresentanteSuplenteValidacion from '../../validation/InfoRepresentanteSuplente';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
props: {
        scopeRepresentanteSuplente: String,
        parametricas: {},
        infoFormularioRLSuplente: {}
    },
    setup(props) {

        let [objetoInfoRepresentanteSuplente, reglasValidacion] = new InfoRepresentanteSuplenteValidacion().ObtenerReglasValidacion();

        const AgregarFilaRLSuplente = () => {

            objetoInfoRepresentanteSuplente.RLSuplente.push({
                NombreRLSuplente: '',
                TipoIdRLSuplente: '',
                NumeroIdentificacionRLSuplente: '',
                CargoEmpresaRLSuplente: '',
                CorreoRLSuplente: '',
                EsPEPRLSuplente: '',
                VinculosFamPEP_RLSuplente: ''

            }); 
        }

        const EliminarFilaRLSuplente = (index) => {
            objetoInfoRepresentanteSuplente.RLSuplente.splice(index, 1);
        }

        const validacionInfoRepresentanteSuplente = useVuelidate(reglasValidacion, objetoInfoRepresentanteSuplente, { $scope: props.scopeRepresentanteSuplente })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onMounted(() => {
          let i;

        if(props.infoFormularioRLSuplente != "No se encuentra diligenciamiento del formulario para el empleador consultado" && props.infoFormularioRLSuplente.rlSuplente != null)
        {      
          if((props.infoFormularioRLSuplente.rlSuplente.length > 0) && (Object.values(props.infoFormularioRLSuplente.rlSuplente[0]).some(x => x !== null && x !== '' && x !== undefined)))
          {
            objetoInfoRepresentanteSuplente.RLSuplente.splice(0, 1);
          }

             for(i = 0; i < props.infoFormularioRLSuplente.rlSuplente.length; i++)
            {
                if(Object.values(props.infoFormularioRLSuplente.rlSuplente[i]).some(x => x !== null && x !== '' && x !== undefined))
                {
                    objetoInfoRepresentanteSuplente.RLSuplente.push({
                        NombreRLSuplente: props.infoFormularioRLSuplente.rlSuplente[i].nombreRLSuplente,
                        TipoIdRLSuplente: props.infoFormularioRLSuplente.rlSuplente[i].tipoIdRLSuplente,
                        NumeroIdentificacionRLSuplente: props.infoFormularioRLSuplente.rlSuplente[i].numeroIdentificacionRLSuplente,
                        CargoEmpresaRLSuplente: props.infoFormularioRLSuplente.rlSuplente[i].cargoEmpresaRLSuplente,
                        CorreoRLSuplente: props.infoFormularioRLSuplente.rlSuplente[i].correoRLSuplente,
                        EsPEPRLSuplente: props.infoFormularioRLSuplente.rlSuplente[i].esPEPRLSuplente,
                        VinculosFamPEP_RLSuplente: props.infoFormularioRLSuplente.rlSuplente[i].vinculosFamPEP_RLSuplente
                    }); 
                }             
            }
        }
      })

        return { 
          AgregarFilaRLSuplente,  
          EliminarFilaRLSuplente, 
          objetoInfoRepresentanteSuplente,
          validacionInfoRepresentanteSuplente,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEPRS()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoRepresentanteSuplente.RLSuplente.length; i++)
            {
                if(this.objetoInfoRepresentanteSuplente.RLSuplente[i].EsPEPRLSuplente.includes("Si") || this.objetoInfoRepresentanteSuplente.RLSuplente[i].VinculosFamPEP_RLSuplente.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            this.$emit('MostrarSeccionPepRS',Result)

        },
        ValidarCamposFila(index)
        {
          let FilaLlena = !Object.values(this.objetoInfoRepresentanteSuplente.RLSuplente[index]).some(x => x === null || x === '' || x === undefined)
          if(FilaLlena)
          {
            this.$emit('GenerarGuardadoParcial');
          }
        }
    }

})

</script>