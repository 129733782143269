import {computed} from 'vue'
import {useStore} from 'vuex'

export function useState(arr:string[]){
    const store =useStore();
    const keypair= arr.map(s => [s,computed(()=> store.state[s])]);
    return Object.fromEntries(keypair);
}
export function useMutations(arr:string[]){
    const store =useStore();
    const keypair= arr.map(m => [m,(input:any)=> store.commit(m,input)]);
    return Object.fromEntries(keypair);
}
