import { reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class InfoPepAccionista_NaturalValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoPepAccionista_Natural = reactive({
             PepAN:[{
                NombreAN_PEP: '',
                NombrePEP: '',
                TipoIdPEPAN: '',
                NumeroIdentificacionPEPAN: '',
                TipoVinculoPEPAN: '',
                TipoPEPAN: ''
             }]
         });

         const requiredMessage = 'Campo obligatorio';
         
         const reglasValidacion = {
            PepAN: {
                 $each: helpers.forEach({
                    NombreAN_PEP: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     NombrePEP: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoIdPEPAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionPEPAN: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoVinculoPEPAN: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                    TipoPEPAN: {
                        required: helpers.withMessage(requiredMessage, required),
                    },
                 })
             }
         };

        return [objetoInfoPepAccionista_Natural, reglasValidacion] as const;
    }
}

export default InfoPepAccionista_NaturalValidacion;